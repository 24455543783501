(function () {
  'use strict';

  angular.module('app.quicksearch').provider('Searcher', SearcherProvider);

  function SearcherProvider() {
    this.$get = [
      'SearchService',
      function SearcherFactory(SearchService) {
        function Searcher(opts) {
          opts = opts || {};
          this.providers = {};
          this.promise = null;
          this.onSuccessSearch = opts.onSuccessSearch || angular.noop;
          this.onAfterSearch = opts.onAfterSearch || angular.noop;
        }

        Searcher.prototype = {
          constructor: Searcher,

          registerSearchProvider: function (name, provider) {
            if (!name) {
              throw new Error('Searcher: name must be supplied when registering search provider');
            }
            if (this.providers[name]) {
              throw new Error('Searcher: provider ' + name + ' is already register');
            }

            this.providers[name] = provider;
          },

          doSearch: function (term, onlyActive, teamClass) {
            var self = this;

            var currentPromise = (self.promise = SearchService.queryQuickSearch(
              term,
              onlyActive,
              teamClass
            ));

            currentPromise.then(function (response) {
              if (self.promise !== currentPromise) {
                return;
              }

              _.forEach(self.providers, function (provider, name) {
                var extractedResult = provider.extractResult(response);

                self.onSuccessSearch(name, extractedResult);
              });

              self.onAfterSearch();
            });
          },
        };

        return Searcher;
      },
    ];
  }
})();
