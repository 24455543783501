(function () {
  'use strict';

  function gameTitleFilter() {
    return function (game) {
      if (!game || !game.home || !game.away) {
        return '';
      }

      return [
        game.date,
        [game.home.name, game.away.name].join(' - '),
        [game.score_home, game.score_away].join(':'),
        ['(', game.scores, ')'].join(''),
      ].join(' ');
    };
  }

  angular.module('app.common').filter('gameTitle', gameTitleFilter);
})();
