(function () {
  'use strict';

  angular.module('app.quicksearch').service('AgentsSearcher', AgentsSearcherConstructor);

  function AgentsSearcherConstructor(SearchService) {
    this.filters = [];

    this.doSearch = function (term) {
      if (term) {
        return SearchService.searchForAgents(term);
      }
    };

    this.extractResult = function (result) {
      if (result && result.agents instanceof Array) {
        return _.chain(result.agents)
          .map(function (item) {
            return item._source;
          })
          .value();
      }

      return [];
    };
  }
})();
