// TODO: cache streamUrl, so we don't have to generate again if the link hasn't expires
// TODO: handle the case if the streamUrl expire while watching/seeking

export interface PlayerState {
  videoIndex: number;
  videoChangeReason: '' | 'start' | 'upnext' | 'refresh-stream' | 'remote-control' | 'switch-video';
  $state: 'stop' | 'play' | 'pause';
  $source?: {
    url: string;
  };
  $currentTime: number;
  $canPlay: boolean;
  $mediaElement?: any;
}

class ScVideoPlayerController {
  private watchToken;
  private videoUrls;
  private cuePoints;
  private fullScreenOnRightClick: boolean;
  private onStateUpdate;
  private onPlayerReady;
  private onGetStreamUrl;
  private autoPlay: boolean;

  private defaultState: PlayerState;
  private currentState: PlayerState;
  private playerConfig;
  private API;
  private cuePointsForCurrentVideo;
  private $playerReadyDeferred;
  private $playerReadyPromise;
  private $readyPromise;
  private $streamPromise;

  private $canPlayDeferred;
  private $canPlayPromise;

  constructor(
    private $q,
    private $sce,
    private $scope,
    private $timeout,
    private $document,
    private hotkeys,
  ) {
    this.defaultState = {
      videoIndex: -1,
      videoChangeReason: '',
      $state: 'stop', // Possible values are "play", "stop" or "pause"
      $source: null,
      $currentTime: 0,
      $canPlay: false,
      $mediaElement: null,
    };

    this.playerConfig = {
      theme: 'node_modules/videogular/dist/themes/default/videogular.css',
      plugins: {
        controls: {
          autoHide: true,
          autoHideTime: 5000,
        },
      },
    };

    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
  }

  get currentVideoUrl() {
    if (this.videoUrls && this.videoUrls.length) {
      return this.videoUrls[this.currentState.videoIndex];
    }
  }

  public $onInit() {
    this.$playerReadyDeferred = this.$q.defer();
    this.$playerReadyPromise = this.$playerReadyDeferred.promise;

    this.$playerReadyPromise.then(() => {
      this.configurePlayer();
      this.setVideo(0, 'start', this.autoPlay);
      this.onPlayerReady();
    });

    this.hotkeys
      .bindTo(this.$scope)
      .add({
        combo: 'space',
        description: 'Play/Pause',
        callback: () => this.playPause(),
      })
      .add({
        combo: 'left',
        description: 'Move backward 5 sec',
        callback: () => this.seek(-5),
      })
      .add({
        combo: 'right',
        description: 'Move forward 5 sec',
        callback: () => this.seek(5),
      })
      .add({
        combo: 'up',
        description: 'Move forward frame by frame',
        callback: () => this.seek(0.1),
      })
      .add({
        combo: 'down',
        description: 'Move backward frame by frame',
        callback: () => this.seek(-0.1),
      })
      .add({
        combo: 'shift+left',
        description: 'Move backward 10 sec',
        callback: () => this.seek(-10),
      })
      .add({
        combo: 'shift+right',
        description: 'Move forward 10 sec',
        callback: () => this.seek(10),
      });

    this.$scope.$watch(
      () => this.API.isFullScreen,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.$document.find('vg-fullscreen-button > button').blur();
        }
      },
    );
  }

  public $onChanges(changesObj) {
    if (changesObj.remoteControl && changesObj.remoteControl.currentValue) {
      this.setPlayerState(changesObj.remoteControl.currentValue);
    }

    if (
      changesObj.cuePoints &&
      changesObj.cuePoints.currentValue &&
      changesObj.cuePoints.currentValue.length
    ) {
      this.generateCuePointsForCurrentVideo(changesObj.cuePoints.currentValue);
    }
  }

  public $postLink() {
    window.addEventListener('click', this.handleDocumentClick, true);
    document.addEventListener('mousedown', this.handleRightClick, true);
    document.addEventListener('contextmenu', this.handleContextMenu, true);
  }

  public $onDestroy() {
    window.removeEventListener('click', this.handleDocumentClick, true);
    document.removeEventListener('mousedown', this.handleRightClick, true);
    document.removeEventListener('contextmenu', this.handleContextMenu, true);
  }

  public handleDocumentClick() {
    const inputs = ['input', 'textarea', 'select'];
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) === -1) {
      activeElement.blur();
    }
  }

  public handleRightClick(e) {
    if (e && e.button === 2 && this.fullScreenOnRightClick) {
      e.preventDefault();
      this.API.toggleFullScreen();
      return false;
    }
  }

  public handleContextMenu(e) {
    if (this.fullScreenOnRightClick) {
      e.preventDefault();
    }
  }

  public playPause() {
    if (!this.currentState) {
      return;
    }
    this.setPlayerState({
      state: this.currentState.$state === 'play' ? 'pause' : 'play',
    });
  }
  public seek(sec: number) {
    if (!this.currentState) {
      return;
    }
    const currentTime = this.API.currentTime / 1000;
    this.setPlayerState({currentTime: Math.max(currentTime + sec, 0)});
  }

  public setPlayerState(controlState) {
    const $videoChangePromise = this.$q((resolve) => {
      if (!controlState.videoUrl) {
        resolve();
      } else {
        const index = this.videoUrls.indexOf(controlState.videoUrl);

        if (
          index !== this.currentState.videoIndex ||
          controlState.videoChangeReason === 'switch-video'
        ) {
          this.setVideo(index, controlState.videoChangeReason || 'remote-control');
          resolve(this.$canPlayPromise);
        } else {
          resolve();
        }
      }
    });

    $videoChangePromise.then(() => {
      if (controlState.currentTime !== null && controlState.currentTime >= 0) {
        let targetTime = Math.max(controlState.currentTime, 0);
        targetTime = Math.min(targetTime, this.API.mediaElement[0].duration || 99999);

        this.API.seekTime(targetTime);
        // this.$canPlayPromise.then(() => this.API.seekTime(targetTime))
      }

      if (controlState.state) {
        if (controlState.state === 'play') {
          this.$timeout(() => this.API.play(), 0);
          this.$canPlayPromise.then(() => this.API.play());
        } else if (controlState.state === 'pause') {
          this.API.pause();
        } else {
          this.API.stop();
        }
      }
    });
  }

  public configurePlayer() {
    this.currentState = {
      ...this.defaultState,
      $mediaElement: this.API.mediaElement[0],
    };
  }

  public refreshStream() {
    this.setVideo(this.currentState.videoIndex, 'refresh-stream', true);
  }

  public setVideo(index, reason, play = true) {
    const $prevState = this.currentState;
    this.currentState = {
      ...this.currentState,
      videoIndex: index,
      videoChangeReason: reason,
    };
    this.onStateUpdate({$currentState: this.currentState, $prevState});

    this.$canPlayDeferred = this.$q.defer();
    this.$canPlayPromise = this.$canPlayDeferred.promise;

    this.$canPlayPromise.then(() => {
      if (play) {
        this.API.play.bind(this.API);
      }
    });

    this.$streamPromise = this.onGetStreamUrl({
      $watchToken: this.watchToken,
      $url: this.currentVideoUrl,
    }).then((streamUrl) => {
      this.playerConfig.sources = [
        {
          src: this.$sce.trustAsResourceUrl(streamUrl),
          url: this.currentVideoUrl,
          type: 'video/mp4',
        },
      ];
      this.$streamPromise = null;
      this.generateCuePointsForCurrentVideo(this.cuePoints);
    });
  }

  public vgPlayerReady(API) {
    // give the player sometime to render / animated in
    this.$timeout(() => {
      this.$playerReadyDeferred.resolve(API);
    }, 500);
    this.API = API;
    this.onStateUpdate({$currentState: this.currentState, $prevState: null});
  }

  public vgComplete() {
    let nextIndex = this.currentState.videoIndex + 1;

    if (nextIndex >= this.videoUrls.length) {
      nextIndex = 0;
    }

    this.setVideo(nextIndex, 'upnext');
  }

  public vgSeeking($currentTime, $duration) {
    const $prevState = this.currentState;
    this.currentState = {
      ...this.currentState,
      $currentTime,
    };
    this.onStateUpdate({$currentState: this.currentState, $prevState, $seeking: true});
  }

  public vgSeeked(time, duration) {
    // do nothing for now
  }

  public vgUpdateTime($currentTime) {
    const $prevState = this.currentState;
    this.currentState = {
      ...this.currentState,
      $currentTime,
    };
    this.onStateUpdate({$currentState: this.currentState, $prevState});
  }

  public vgUpdateState($state) {
    const $prevState = this.currentState;
    this.currentState = {
      ...this.currentState,
      $state,
    };
    this.onStateUpdate({$currentState: this.currentState, $prevState});
  }

  public vgChangeSource($source) {
    const $prevState = this.currentState;
    this.currentState = {
      ...this.currentState,
      $source,
      $canPlay: false,
    };
    this.onStateUpdate({$currentState: this.currentState, $prevState});
    this.generateCuePointsForCurrentVideo(this.cuePoints);
  }

  public vgCanPlay($event) {
    if (this.$canPlayDeferred) {
      this.$canPlayDeferred.resolve($event);
    }

    const $prevState = this.currentState;
    this.currentState = {
      ...this.currentState,
      $canPlay: true,
    };
    this.onStateUpdate({$currentState: this.currentState, $prevState});
  }

  public vgError(event) {
    (window as any).trackJs &&
      (window as any).trackJs.track(
        `Video player error. Video URL: ${event.srcElement.currentSrc}. Error Message: ${event.srcElement.error.message}. Media Error Code: ${event.srcElement.error.code}`,
      );
  }

  public generateCuePointsForCurrentVideo(cuePoints) {
    if (!this.currentState || !this.currentState.$source) {
      return;
    }
    const {url} = this.currentState.$source;
    if (cuePoints && cuePoints.length) {
      this.cuePointsForCurrentVideo = {
        events: this.cuePoints.filter((cuePoint) => cuePoint.params.videoUrl === url),
      };
      this.playerConfig.cuePoints = this.cuePointsForCurrentVideo;
    }
  }
}

angular.module('app.video').component('scVideoPlayer', {
  templateUrl: 'common/video/components/sc-video-player.html',
  bindings: {
    watchToken: '<',
    videoUrls: '<',
    cuePoints: '<',
    remoteControl: '<',
    fullScreenOnRightClick: '<',
    onStateUpdate: '&',
    onPlayerReady: '&',
    onGetStreamUrl: '&',
    autoPlay: '<',
  },
  transclude: true,
  controller: ScVideoPlayerController,
});
