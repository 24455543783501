(function () {
  'use strict';

  angular.module('app.common').provider('SCConfiguration', SCConfigurationProvider);

  function SCConfigurationProvider() {
    var endpointUrl = '',
      videoUploadUrl = '',
      useMongoSearchProvider = true;

    this.configureEndpoint = function (url) {
      endpointUrl = url;
    };

    this.configureVideoUploadEndpoint = function (url) {
      videoUploadUrl = url;
    };
    this.useMongoSearchProvider = function (choice) {
      useMongoSearchProvider = choice;
    };

    this.$get = $get;

    function $get() {
      return {
        getEndpoint: function () {
          return endpointUrl;
        },

        getVideoUploadEndpoint: function () {
          return videoUploadUrl;
        },

        getAdvancedSearchEndpoint: function () {
          var api = useMongoSearchProvider
            ? '/api/advancedSearch/players/mongo'
            : '/api/advancedSearch/players';
          return endpointUrl + api;
        },
        getAdvancedSearchProvider: function () {
          return useMongoSearchProvider ? 'mongo' : 'elastic';
        },
      };
    }
  }
})();
