(function () {
  'use strict';

  angular.module('app.common').filter('videoTime', VideoTimeFilter);

  function VideoTimeFilter($filter) {
    return function (input) {
      input = parseFloat(input);

      if (isNaN(input)) {
        return '';
      }

      if (input < 0) {
        input = 0;
      }

      var result = $filter('date')(input, 'HH:mm:ss', '+0000');

      if (!result) {
        return;
      }

      var parts = result.split(':');

      if (parts[0] === '00') {
        return parts[1] + ':' + parts[2];
      }

      return result;
    };
  }
})();
