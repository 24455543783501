(function () {
  'use strict';

  angular.module('app.common').directive('scDateModelAdapter', scDateModelAdapterDirective);

  function scDateModelAdapterDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',
      priority: 100,
      link: function ($scope, $element, $attr, ngModel) {
        ngModel.$formatters.push(dateFormatter);
        ngModel.$parsers.push(dateParser);
      },
    };

    function dateFormatter(value) {
      if (value) {
        return moment(value).toDate();
      }
    }

    function dateParser(value) {
      if (value && value instanceof Date) {
        return moment(value).format('YYYY-MM-DD');
      }

      return value;
    }
  }
})();
