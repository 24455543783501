(function () {
  'use strict';

  angular.module('app.common').directive('loading', loadingDirective);

  function loadingDirective() {
    return {
      restrict: 'E',
      templateUrl: 'common/common/views/loadingDirective.html',
    };
  }
})();
