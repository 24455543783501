(function () {
  'use strict';

  angular.module('app.common').directive('mediaLink', mediaLinkDirective);

  function mediaLinkDirective() {
    return {
      restrict: 'E',
      scope: {
        link: '=',
      },
      templateUrl: 'common/common/views/mediaLink.html',
    };
  }
})();
