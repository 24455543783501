(function () {
  'use strict';

  angular.module('app.quicksearch').service('SearchService', SearchService);

  function SearchService($resource, SCConfiguration) {
    var searchResource = $resource(
      SCConfiguration.getEndpoint() + '/api/search/:what/:term/:modifier',
      {},
      {
        searchForTeams: {
          method: 'GET',
          params: {what: 'teams'},
        },
        searchForLeagues: {
          method: 'GET',
          params: {what: 'leagues'},
        },
        searchForPlayers: {
          method: 'GET',
          params: {what: 'players'},
        },
        searchForAllAvailablePlayers: {
          method: 'GET',
          params: {what: 'players', modifier: 'all'},
        },
        searchForStaffs: {
          method: 'GET',
          params: {what: 'staffs'},
        },
        searchForAllAvailableStaffs: {
          method: 'GET',
          params: {what: 'staffs', modifier: 'all'},
        },
        searchForCountries: {
          method: 'GET',
          params: {what: 'countries'},
        },
        searchForOrganizations: {
          method: 'GET',
          params: {what: 'organizations'},
        },
        searchForUsers: {
          method: 'GET',
          params: {what: 'users'},
        },
        searchForAgents: {
          method: 'GET',
          params: {what: 'agents'},
        },
        queryQuickSearch: {
          method: 'GET',
          isArray: false,
        },
      }
    );

    this.searchForTeams = function (term, contactable) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForTeams` method'
        );
      }
      var params = {term: term};

      if (contactable) {
        params.contactable = true;
      }

      return searchResource.searchForTeams(params).$promise;
    };

    this.searchForLeagues = function (term, teamClass) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForLeagues` method'
        );
      }
      return searchResource.searchForLeagues({term: term, teamClass: teamClass || 'MEN'}).$promise;
    };

    this.searchForPlayers = function (term, params) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForPlayers` method'
        );
      }

      var query = _.assignIn({term: term}, params);

      return searchResource.searchForPlayers(query).$promise;
    };

    this.searchForAllAvailablePlayers = function (term) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForPlayers` method'
        );
      }
      return searchResource.searchForAllAvailablePlayers({term: term}).$promise;
    };

    this.searchForStaffs = function (term) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForStaffs` method'
        );
      }
      return searchResource.searchForStaffs({term: term}).$promise;
    };

    this.searchForAllAvailableStaffs = function (term) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForStaffs` method'
        );
      }
      return searchResource.searchForAllAvailableStaffs({term: term}).$promise;
    };

    this.searchForCountries = function (term) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForCountries` method'
        );
      }
      return searchResource.searchForCountries({term: term}).$promise;
    };

    this.searchForOrganizations = function (term, params) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForOrganizations` method'
        );
      }

      var query = _.assignIn({term: term}, params);

      return searchResource.searchForOrganizations(query).$promise;
    };

    this.searchForUsers = function (term) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForUser` method'
        );
      }
      return searchResource.searchForUsers({term: term}).$promise;
    };

    this.searchForAgents = function (term) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `searchForAgents` method'
        );
      }
      return searchResource.searchForAgents({term: term}).$promise;
    };

    this.queryQuickSearch = function (term, onlyActive, teamClass) {
      if (typeof term === 'undefined') {
        throw new Error(
          'Invalid Argument exception: `term` argument should be set for the `queryQuickSearch` method'
        );
      }
      return searchResource.queryQuickSearch({
        term: term,
        'only-active': onlyActive,
        teamClass: teamClass || 'MEN',
      }).$promise;
    };
  }
})();
