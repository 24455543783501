class SCAutoHideController {
  private isPlaying;

  private visible: boolean;
  private isMouseInside: boolean;
  private timer;

  constructor(private $document) {}

  $onInit() {
    this.visible = true;
    this.$document.bind('mousemove.scautohide', this.onMouseMove.bind(this));
  }

  $onChanges(changesObj) {
    if (!changesObj.isPlaying.currentValue) {
      this.visible = true;
      this.stopTimer();
    } else {
      this.startTimer();
    }
  }

  $onDestroy() {
    this.$document.off('mousemove.scautohide');
  }

  onMouseMove() {
    if (this.isMouseInside || !this.isPlaying) {
      return;
    }

    if (!this.visible) {
      this.visible = true;
    }

    this.startTimer();
  }

  onMouseLeave() {
    this.isMouseInside = false;
  }

  onMouseEnter() {
    this.isMouseInside = true;
  }

  startTimer(delay?: number) {
    this.stopTimer();
    this.timer = setTimeout(() => {
      if (!this.isMouseInside && this.isPlaying) {
        this.visible = false;
      }
    }, delay || 3000);
  }

  stopTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
  }
}

angular.module('app.video').component('scAutoHide', {
  templateUrl: 'common/video/components/sc-auto-hide.html',
  transclude: true,
  bindings: {
    isPlaying: '<',
  },
  controller: SCAutoHideController as any,
});
