(function () {
  'use strict';

  function StateInterceptor($state, $rootScope) {
    var interceptorsArray = [];

    var stateChangeListener = function (event, toState, toParams, fromState, fromParams) {
      interceptorsArray.forEach(function (interceptor) {
        var isInterceptorShouldBeIncluded = interceptor.check_function(
          event,
          toState,
          toParams,
          fromState,
          fromParams
        );

        if (isInterceptorShouldBeIncluded) {
          event.preventDefault();

          var interceptorResult = interceptor.execute_function(
            event,
            toState,
            toParams,
            fromState,
            fromParams
          );

          interceptorResult.then(function () {
            $state.go(toState, toParams);
          });
        }
      });
    };

    var init = function () {
      $rootScope.$on('$stateChangeStart', stateChangeListener);
    };

    var checkInterceptorStartParams = function (name, check, execute, params) {
      if (typeof name !== 'string' || name.length == 0) {
        throw new Error("'name' parameter should be a non-empty string!");
      }

      if (typeof check !== 'function') {
        throw new Error("'check' parameter should be a Function!");
      }

      if (typeof execute !== 'function') {
        throw new Error("'execute' parameter should be a Function!");
      }

      if (typeof params !== 'object') {
        throw new Error("'param' parameter should be an Object!");
      }
    };

    var addInterceptorStart = function (name, check, execute, params) {
      checkInterceptorStartParams(name, check, execute, params);

      interceptorsArray.push({
        name: name,
        check_function: check,
        execute_function: execute,
        parameters: params,
      });
    };

    var removeInterceptorStart = function (name) {
      var interceptorCount = interceptorsArray.length;

      interceptorsArray = _.reject(interceptorsArray, function (item) {
        return item.name == name;
      });

      if (interceptorCount == interceptorsArray.length) {
        throw new Error('no matching interceptor to remove!');
      }
    };

    init();

    return {
      addInterceptorStart: addInterceptorStart,
      removeInterceptorStart: removeInterceptorStart,
    };
  }

  var commonModutle = angular.module('app.common');
  commonModutle.service('StateInterceptor', StateInterceptor);
})();
