(function () {
  'use strict';

  angular.module('app.acl').directive('scRestrictedAccess', scRestrictedAccessDirective);

  function scRestrictedAccessDirective(ACLService, UserService, $q, $compile) {
    return {
      restrict: 'E',
      transclude: true,

      link: function (scope, el, attrs, ctrl, transclude) {
        var permissions = attrs.permissions;
        var user = UserService.getUser();

        scope.message = attrs.message;

        if (permissions && !ACLService.hasPermissions(user, permissions)) {
          el.append(
            $compile(angular.element('<access-denied message="message"></access-denied>'))(scope)
          );
        } else {
          el.append(transclude());
        }
      },
    };
  }
})();
