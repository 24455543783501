(function () {
  'use strict';

  var currencies = {
    eur: '€',
    usd: '$',
    chf: 'CHF',
  };

  function isoCurrency($filter) {
    return function (input, currency, fraction) {
      var symbol = (currencies[currency] || _.upperCase(currency)) + ' ';
      return $filter('currency')(input, symbol, fraction);
    };
  }

  angular.module('app.common').filter('isoCurrency', isoCurrency);
})();
