(function (_) {
  'use strict';

  var commonModule = angular.module('app.common');
  commonModule.factory('_', LodashMixinsFactory);

  function joinIfPresent(separator /*, strings */) {
    var argsLength = arguments.length,
      strings = [];

    for (var i = 1, j = 0; i < argsLength; ++i) {
      var arg = arguments[i];

      if (arg) {
        strings[j++] = arg;
      }
    }

    return strings.join(separator);
  }

  //TODO: Should replace all lodash usages with this
  function LodashMixinsFactory() {
    _.mixin({joinIfPresent: joinIfPresent});
    return _;
  }
})(_);
