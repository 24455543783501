(function () {
  'use strict';

  function extractValueToCompare(item, filterOn) {
    var filterArr = filterOn.split('.');

    for (var i = 0; i < filterArr.length; i++) {
      if (!item) return null;
      item = item[filterArr[i]];
    }
    return item;
  }

  var uniqueFilter = function () {
    return function (items, filterOn) {
      if (angular.isString(filterOn) && angular.isArray(items)) {
        return _.uniqBy(items, function (item) {
          return extractValueToCompare(item, filterOn);
        });
      }

      return items;
    };
  };

  var commonModule = angular.module('app.common');
  commonModule.filter('unique', uniqueFilter);
})();
