(function () {
  'use strict';

  angular.module('app.common').factory('PaginatorNormalStrategy', function () {
    return PaginatorNormalStrategy;
  });

  function PaginatorNormalStrategy(PipelineTransformer) {
    this.data = [];
    this.hasMoreRows = false;

    this.successPipeline = new PipelineTransformer(this);
    this.successPipeline.push(this.onRequestFulfilled);

    this.errorPipeline = new PipelineTransformer(this);
    this.errorPipeline.push(this.onRequestFailed);
  }

  /**
   * REQUIRED INTERFACES By PaginatorBaseFactory
   */
  PaginatorNormalStrategy.prototype.isBusy = function () {
    return !!this.lastRequest && !isRequestFulfilled(this.lastRequest);
  };

  PaginatorNormalStrategy.prototype.requestRows = function (
    appendResult,
    paginationModel,
    overrides
  ) {
    var normalModel = paginationModel;
    if (overrides) {
      normalModel = angular.extend({}, paginationModel, overrides);
    }

    this.createRequest({
      appendResult: appendResult,
      model: normalModel,
    });
  };

  PaginatorNormalStrategy.prototype.reset = function (clearData) {
    if (clearData) {
      this.data = [];
    }
    this.request = this.lastRequest = undefined;
    this.hasMoreRows = false;
    this.lastPromise = undefined;
  };

  PaginatorNormalStrategy.prototype.retryLastRequest = function () {
    if (this.lastRequest) {
      this.lastRequest.promise = this.getPromiseForRequest(this.lastRequest);
      this.lastPromise = this.lastRequest.promise;

      return this.lastPromise;
    }

    throw new Error('Last request was success. You can not retry it!');
  };

  /**
   * STRATEGY's private functions
   */
  PaginatorNormalStrategy.prototype.createRequest = function (request) {
    if (isSameRequest(request, this.lastRequest) && !isRequestFulfilled(this.lastRequest)) {
      this.lastRequest.appendResult = request.appendResult;
      return;
    }

    this.hasMoreRows = false;

    request.promise = this.getPromiseForRequest(request);

    this.lastRequest = request;
    this.lastPromise = this.lastRequest.promise;
  };

  PaginatorNormalStrategy.prototype.onRequestFulfilled = function (request, data) {
    if (request === this.lastRequest) {
      this._appendData(request, data || []);
      this.lastRequest = undefined;
      return data || [];
    }
  };

  PaginatorNormalStrategy.prototype.onRequestFailed = function (/* request */) {
    this.hasMoreRows = false;
    return true;
  };

  PaginatorNormalStrategy.prototype.getPromiseForRequest = function () {
    throw new Error('Paginator should override "getPromiseForRequest" method of strategy.');
  };

  PaginatorNormalStrategy.prototype._appendData = function (request, data) {
    if (request.appendResult) {
      this.data = this.data.concat(data);
    } else {
      this.data = data;
    }

    if (request.model && typeof request.model.hasMoreRows !== 'undefined') {
      this.hasMoreRows = request.model.hasMoreRows;
    } else {
      this.hasMoreRows = request.model && request.model.limit <= data.length;
    }
  };

  function isRequestFulfilled(request) {
    return !!request && !!request.promise && request.promise.$$state.status === 1;
  }

  function isSameRequest(request, reference) {
    return !!reference && angular.equals(request.model, reference.model);
  }
})();
