(function () {
  'use strict';

  angular.module('app.common').directive('loadingError', loadingErrorDirective);

  function loadingErrorDirective() {
    return {
      restrict: 'E',
      templateUrl: 'common/common/views/loadingErrorDirective.html',

      require: '^loadingWrapper',
      scope: true,

      link: function ($scope, $element, $attr, $controller) {
        $scope.tryAgain = $controller.tryAgain;
        $scope.isRetryAvailable = $controller.isRetryAvailable;
      },
    };
  }
})();
