(function () {
  'use strict';

  // https://gist.github.com/niyazpk/f8ac616f181f6042d1e0
  function updateUrlParameter(uri, key, value) {
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? '' : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';

    if (!value) {
      // remove key-value pair if value is empty
      uri = uri.replace(new RegExp('([?&]?)' + key + '=[^&]*', 'i'), '');
      if (uri.slice(-1) === '?') {
        uri = uri.slice(0, -1);
      }
      // replace first occurrence of & by ? if no ? is present
      if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
      uri = uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      uri = uri + separator + key + '=' + value;
    }
    return uri + hash;
  }

  function SeasonInterceptorFactory(SeasonService) {
    var isNotHtml = function (config) {
      return config.url.indexOf('html') === -1;
    };

    var isNotJson = function (config) {
      return config.url.indexOf('json') === -1;
    };

    var isApiRequest = function (config) {
      return config.url.indexOf('api') > -1;
    };

    return {
      request: function (config) {
        if (isNotHtml(config) && isNotJson(config) && isApiRequest(config)) {
          var currentSeason = SeasonService.getSeason();

          if (!config.params || !config.params.season) {
            config.url = updateUrlParameter(config.url, 'season', currentSeason);
          }
        }

        return config;
      },
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.factory('SeasonInterceptorFactory', SeasonInterceptorFactory);
})();
