(function () {
  angular.module('app.common').directive('loadMore', loadMoreDirective);

  function loadMoreDirective($document, $window, $timeout) {
    return {
      restrict: 'E',
      scope: {
        more: '&',
        hasMore: '<',
        wait: '<',
        reverse: '<',
        delay: '<',
        endlessScroll: '<',
        scrollElement: '@',
      },
      templateUrl: 'common/common/views/loadMoreDirective.html',
      link: function ($scope, $element, $attr) {
        let offset = parseInt($attr.offset, 10);
        offset = isNaN(offset) ? 20 : offset;

        const $scrollElement = $scope.scrollElement
          ? angular.element($document[0].querySelector('#' + $scope.scrollElement))
          : $document;

        const $containerElement = $scope.scrollElement
          ? angular.element($document[0].querySelector('#' + $scope.scrollElement))
          : angular.element($document[0].body);

        const throttledHandler = _.throttle(function () {
          $timeout(checkPosition, 0);
        }, 100);

        if ($scope.endlessScroll) {
          setTimeout(function () {
            $scrollElement.on('scroll', throttledHandler);
            angular.element($window).on('resize', throttledHandler);

            throttledHandler();

            const unwatch = $scope.$watch('wait', function (newWait) {
              if (newWait && newWait.then) {
                newWait.then(function () {
                  $timeout(checkPosition, 0);
                });
              }
            });

            $scope.$on('$destroy', function () {
              $scrollElement.unbind('scroll', throttledHandler);
              angular.element($window).unbind('resize', throttledHandler);
              unwatch();
            });
          }, $scope.delay || 0);
        }

        function checkPosition() {
          if ($scope.wait && $scope.wait.$$state.status === 0) {
            return;
          }

          if ($scope.hasMore === false) {
            return;
          }

          if ($scope.reverse) {
            if ($containerElement.scrollTop() - offset <= 0) {
              $scope.more();
            }
          } else {
            /*
                            if ($element.offset().top - $containerElement.offset().top < $containerElement.scrollTop() + $containerElement.height() + offset) {
                                 $scope.$apply($scope.more());
                            }

                            The code above works until recently, $containerElement.scrollTop() now always return 0 for document.body
                            The workaround is to use $scrollElement.scrollTop() instead
                        */

            const shouldLoadMore =
              $containerElement[0].tagName === 'BODY'
                ? $element.offset().top - $containerElement.offset().top <
                  $scrollElement.scrollTop() + $containerElement.height() + offset
                : $element.offset().top - $containerElement.offset().top <
                  $containerElement.height() + offset;
            if (shouldLoadMore) {
              $scope.more();
            }
          }
        }
      },
    };
  }
})();
