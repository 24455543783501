(function () {
  'use strict';

  angular.module('app.common').component('loadingSpinner', {
    templateUrl: 'common/common/components/loading-spinner.html',
    bindings: {
      waitFor: '<',
    },
  });
})();
