(function () {
  'use strict';

  angular.module('app.quicksearch').service('PlayerSearcher', PlayerSearcherConstructor);

  function PlayerSearcherConstructor(SearchService) {
    this.filters = [];

    this.doSearch = function (term) {
      if (term) {
        return SearchService.searchForPlayers(term);
      }
    };

    this.extractResult = function (result) {
      if (result && result.players instanceof Array) {
        return _.chain(result.players)
          .map(function (player) {
            return player._source;
          })
          .value();
      }

      return [];
    };
  }
})();
