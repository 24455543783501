(function () {
  'use strict';

  function TimeToSecondsFilter() {
    return function (timeStr) {
      if (!timeStr || !timeStr.split) {
        return 0;
      }

      var parts = timeStr.split(':');

      if (!parts.length || parts.length > 3) {
        return 0;
      }

      if (parts.length === 3) {
        return parseInt(parts[0]) * 60 * 60 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
      } else if (parts.length === 2) {
        return parseInt(parts[0]) * 60 + parseInt(parts[1]);
      } else if (parts.length === 1) {
        return parseInt(parts[0]);
      }
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('timeToSeconds', TimeToSecondsFilter);
})();
