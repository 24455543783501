(function () {
  'use strict';

  angular.module('app.quicksearch').service('TeamSearcher', TeamSearcherConstructor);

  function TeamSearcherConstructor(SearchService) {
    this.filters = [];

    this.doSearch = function (term) {
      if (term) {
        return SearchService.searchForTeams(term);
      }
    };

    this.extractResult = function (result) {
      if (result && result.teams instanceof Array) {
        return _.chain(result.teams)
          .map(function (item) {
            return item._source;
          })
          .value();
      }

      return [];
    };
  }
})();
