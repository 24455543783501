(function () {
  'use strict';

  angular.module('app.common').directive('scBindHtml', scBindHtmlDirective);

  function scBindHtmlDirective($parse, $compile) {
    return {
      restrict: 'AE',

      link: function ($scope, $element, $attrs) {
        var watchable = $parse($attrs.scBindHtml);

        $scope.$watch(watchable, function (html) {
          html = $compile('<span>' + html + '</span>')($scope);
          $element.html(html);
        });
      },
    };
  }
})();
