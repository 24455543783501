var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var ScVideoPlayerController = (function () {
    function ScVideoPlayerController($q, $sce, $scope, $timeout, $document, hotkeys) {
        this.$q = $q;
        this.$sce = $sce;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$document = $document;
        this.hotkeys = hotkeys;
        this.defaultState = {
            videoIndex: -1,
            videoChangeReason: '',
            $state: 'stop',
            $source: null,
            $currentTime: 0,
            $canPlay: false,
            $mediaElement: null,
        };
        this.playerConfig = {
            theme: 'node_modules/videogular/dist/themes/default/videogular.css',
            plugins: {
                controls: {
                    autoHide: true,
                    autoHideTime: 5000,
                },
            },
        };
        this.handleRightClick = this.handleRightClick.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
    }
    Object.defineProperty(ScVideoPlayerController.prototype, "currentVideoUrl", {
        get: function () {
            if (this.videoUrls && this.videoUrls.length) {
                return this.videoUrls[this.currentState.videoIndex];
            }
        },
        enumerable: false,
        configurable: true
    });
    ScVideoPlayerController.prototype.$onInit = function () {
        var _this = this;
        this.$playerReadyDeferred = this.$q.defer();
        this.$playerReadyPromise = this.$playerReadyDeferred.promise;
        this.$playerReadyPromise.then(function () {
            _this.configurePlayer();
            _this.setVideo(0, 'start', _this.autoPlay);
            _this.onPlayerReady();
        });
        this.hotkeys
            .bindTo(this.$scope)
            .add({
            combo: 'space',
            description: 'Play/Pause',
            callback: function () { return _this.playPause(); },
        })
            .add({
            combo: 'left',
            description: 'Move backward 5 sec',
            callback: function () { return _this.seek(-5); },
        })
            .add({
            combo: 'right',
            description: 'Move forward 5 sec',
            callback: function () { return _this.seek(5); },
        })
            .add({
            combo: 'up',
            description: 'Move forward frame by frame',
            callback: function () { return _this.seek(0.1); },
        })
            .add({
            combo: 'down',
            description: 'Move backward frame by frame',
            callback: function () { return _this.seek(-0.1); },
        })
            .add({
            combo: 'shift+left',
            description: 'Move backward 10 sec',
            callback: function () { return _this.seek(-10); },
        })
            .add({
            combo: 'shift+right',
            description: 'Move forward 10 sec',
            callback: function () { return _this.seek(10); },
        });
        this.$scope.$watch(function () { return _this.API.isFullScreen; }, function (newVal, oldVal) {
            if (newVal !== oldVal) {
                _this.$document.find('vg-fullscreen-button > button').blur();
            }
        });
    };
    ScVideoPlayerController.prototype.$onChanges = function (changesObj) {
        if (changesObj.remoteControl && changesObj.remoteControl.currentValue) {
            this.setPlayerState(changesObj.remoteControl.currentValue);
        }
        if (changesObj.cuePoints &&
            changesObj.cuePoints.currentValue &&
            changesObj.cuePoints.currentValue.length) {
            this.generateCuePointsForCurrentVideo(changesObj.cuePoints.currentValue);
        }
    };
    ScVideoPlayerController.prototype.$postLink = function () {
        window.addEventListener('click', this.handleDocumentClick, true);
        document.addEventListener('mousedown', this.handleRightClick, true);
        document.addEventListener('contextmenu', this.handleContextMenu, true);
    };
    ScVideoPlayerController.prototype.$onDestroy = function () {
        window.removeEventListener('click', this.handleDocumentClick, true);
        document.removeEventListener('mousedown', this.handleRightClick, true);
        document.removeEventListener('contextmenu', this.handleContextMenu, true);
    };
    ScVideoPlayerController.prototype.handleDocumentClick = function () {
        var inputs = ['input', 'textarea', 'select'];
        var activeElement = document.activeElement;
        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) === -1) {
            activeElement.blur();
        }
    };
    ScVideoPlayerController.prototype.handleRightClick = function (e) {
        if (e && e.button === 2 && this.fullScreenOnRightClick) {
            e.preventDefault();
            this.API.toggleFullScreen();
            return false;
        }
    };
    ScVideoPlayerController.prototype.handleContextMenu = function (e) {
        if (this.fullScreenOnRightClick) {
            e.preventDefault();
        }
    };
    ScVideoPlayerController.prototype.playPause = function () {
        if (!this.currentState) {
            return;
        }
        this.setPlayerState({
            state: this.currentState.$state === 'play' ? 'pause' : 'play',
        });
    };
    ScVideoPlayerController.prototype.seek = function (sec) {
        if (!this.currentState) {
            return;
        }
        var currentTime = this.API.currentTime / 1000;
        this.setPlayerState({ currentTime: Math.max(currentTime + sec, 0) });
    };
    ScVideoPlayerController.prototype.setPlayerState = function (controlState) {
        var _this = this;
        var $videoChangePromise = this.$q(function (resolve) {
            if (!controlState.videoUrl) {
                resolve();
            }
            else {
                var index = _this.videoUrls.indexOf(controlState.videoUrl);
                if (index !== _this.currentState.videoIndex ||
                    controlState.videoChangeReason === 'switch-video') {
                    _this.setVideo(index, controlState.videoChangeReason || 'remote-control');
                    resolve(_this.$canPlayPromise);
                }
                else {
                    resolve();
                }
            }
        });
        $videoChangePromise.then(function () {
            if (controlState.currentTime !== null && controlState.currentTime >= 0) {
                var targetTime = Math.max(controlState.currentTime, 0);
                targetTime = Math.min(targetTime, _this.API.mediaElement[0].duration || 99999);
                _this.API.seekTime(targetTime);
            }
            if (controlState.state) {
                if (controlState.state === 'play') {
                    _this.$timeout(function () { return _this.API.play(); }, 0);
                    _this.$canPlayPromise.then(function () { return _this.API.play(); });
                }
                else if (controlState.state === 'pause') {
                    _this.API.pause();
                }
                else {
                    _this.API.stop();
                }
            }
        });
    };
    ScVideoPlayerController.prototype.configurePlayer = function () {
        this.currentState = __assign(__assign({}, this.defaultState), { $mediaElement: this.API.mediaElement[0] });
    };
    ScVideoPlayerController.prototype.refreshStream = function () {
        this.setVideo(this.currentState.videoIndex, 'refresh-stream', true);
    };
    ScVideoPlayerController.prototype.setVideo = function (index, reason, play) {
        var _this = this;
        if (play === void 0) { play = true; }
        var $prevState = this.currentState;
        this.currentState = __assign(__assign({}, this.currentState), { videoIndex: index, videoChangeReason: reason });
        this.onStateUpdate({ $currentState: this.currentState, $prevState: $prevState });
        this.$canPlayDeferred = this.$q.defer();
        this.$canPlayPromise = this.$canPlayDeferred.promise;
        this.$canPlayPromise.then(function () {
            if (play) {
                _this.API.play.bind(_this.API);
            }
        });
        this.$streamPromise = this.onGetStreamUrl({
            $watchToken: this.watchToken,
            $url: this.currentVideoUrl,
        }).then(function (streamUrl) {
            _this.playerConfig.sources = [
                {
                    src: _this.$sce.trustAsResourceUrl(streamUrl),
                    url: _this.currentVideoUrl,
                    type: 'video/mp4',
                },
            ];
            _this.$streamPromise = null;
            _this.generateCuePointsForCurrentVideo(_this.cuePoints);
        });
    };
    ScVideoPlayerController.prototype.vgPlayerReady = function (API) {
        var _this = this;
        this.$timeout(function () {
            _this.$playerReadyDeferred.resolve(API);
        }, 500);
        this.API = API;
        this.onStateUpdate({ $currentState: this.currentState, $prevState: null });
    };
    ScVideoPlayerController.prototype.vgComplete = function () {
        var nextIndex = this.currentState.videoIndex + 1;
        if (nextIndex >= this.videoUrls.length) {
            nextIndex = 0;
        }
        this.setVideo(nextIndex, 'upnext');
    };
    ScVideoPlayerController.prototype.vgSeeking = function ($currentTime, $duration) {
        var $prevState = this.currentState;
        this.currentState = __assign(__assign({}, this.currentState), { $currentTime: $currentTime });
        this.onStateUpdate({ $currentState: this.currentState, $prevState: $prevState, $seeking: true });
    };
    ScVideoPlayerController.prototype.vgSeeked = function (time, duration) {
    };
    ScVideoPlayerController.prototype.vgUpdateTime = function ($currentTime) {
        var $prevState = this.currentState;
        this.currentState = __assign(__assign({}, this.currentState), { $currentTime: $currentTime });
        this.onStateUpdate({ $currentState: this.currentState, $prevState: $prevState });
    };
    ScVideoPlayerController.prototype.vgUpdateState = function ($state) {
        var $prevState = this.currentState;
        this.currentState = __assign(__assign({}, this.currentState), { $state: $state });
        this.onStateUpdate({ $currentState: this.currentState, $prevState: $prevState });
    };
    ScVideoPlayerController.prototype.vgChangeSource = function ($source) {
        var $prevState = this.currentState;
        this.currentState = __assign(__assign({}, this.currentState), { $source: $source, $canPlay: false });
        this.onStateUpdate({ $currentState: this.currentState, $prevState: $prevState });
        this.generateCuePointsForCurrentVideo(this.cuePoints);
    };
    ScVideoPlayerController.prototype.vgCanPlay = function ($event) {
        if (this.$canPlayDeferred) {
            this.$canPlayDeferred.resolve($event);
        }
        var $prevState = this.currentState;
        this.currentState = __assign(__assign({}, this.currentState), { $canPlay: true });
        this.onStateUpdate({ $currentState: this.currentState, $prevState: $prevState });
    };
    ScVideoPlayerController.prototype.vgError = function (event) {
        window.trackJs &&
            window.trackJs.track("Video player error. Video URL: ".concat(event.srcElement.currentSrc, ". Error Message: ").concat(event.srcElement.error.message, ". Media Error Code: ").concat(event.srcElement.error.code));
    };
    ScVideoPlayerController.prototype.generateCuePointsForCurrentVideo = function (cuePoints) {
        if (!this.currentState || !this.currentState.$source) {
            return;
        }
        var url = this.currentState.$source.url;
        if (cuePoints && cuePoints.length) {
            this.cuePointsForCurrentVideo = {
                events: this.cuePoints.filter(function (cuePoint) { return cuePoint.params.videoUrl === url; }),
            };
            this.playerConfig.cuePoints = this.cuePointsForCurrentVideo;
        }
    };
    return ScVideoPlayerController;
}());
angular.module('app.video').component('scVideoPlayer', {
    templateUrl: 'common/video/components/sc-video-player.html',
    bindings: {
        watchToken: '<',
        videoUrls: '<',
        cuePoints: '<',
        remoteControl: '<',
        fullScreenOnRightClick: '<',
        onStateUpdate: '&',
        onPlayerReady: '&',
        onGetStreamUrl: '&',
        autoPlay: '<',
    },
    transclude: true,
    controller: ScVideoPlayerController,
});
;