(function () {
  'use strict';

  angular.module('app.common').service('SystemConfigurationService', SystemConfigurationService);

  function SystemConfigurationService($http, $q, SCConfiguration) {
    var baseUrl = SCConfiguration.getEndpoint() + '/api/configurations';

    this.getActiveConfigurations = function () {
      return $http.get(baseUrl, {cache: true}).then(function (response) {
        return response.data;
      });
    };

    this.getVideoSources = function () {
      return $http.get(baseUrl + '/video-sources', {cache: true}).then(function (response) {
        return response.data;
      });
    };

    this.getPlayerSettings = function () {
      return $http.get(baseUrl + '/video-player-settings', {cache: true}).then(function (response) {
        return response.data;
      });
    };
  }
})();
