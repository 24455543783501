(function () {
  function LeaguesServiceConstructor($http, SCConfiguration) {
    this.getAssociations = function (countryId) {
      var url = getLeaguesBaseUrl(countryId) + '/associations';

      return $http.get(url).then(function (response) {
        return response.data;
      });
    };

    this.getCountryDetails = function (countryId) {
      var url = getLeaguesBaseUrl(countryId);

      return $http.get(url).then(function (response) {
        return response.data;
      });
    };

    this.getAllLeagues = function () {
      return $http.get(SCConfiguration.getEndpoint() + '/api/leagues').then(function (response) {
        return response.data;
      });
    };

    function getLeaguesBaseUrl(countryId) {
      return [SCConfiguration.getEndpoint(), 'api/countries', countryId].join('/');
    }
  }

  angular.module('app.common').service('LeaguesService', LeaguesServiceConstructor);
})();
