(function () {
  'use strict';

  function scTimeFilter(UserService) {
    return function (input, format) {
      if (!input) {
        return null;
      }

      if (!format) {
        format = SportContract.utils.getFieldValue(UserService.getUser(), 'settings.timeformat');
      }

      var result = moment(input);

      return result.isValid() ? result.format(format) : input;
    };
  }

  angular.module('app.common').filter('scTime', scTimeFilter);
})();
