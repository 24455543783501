(function () {
  'use strict';

  function isRequestFulfilled(request) {
    return !!request && !!request.promise && request.promise.$$state.status === 1;
  }

  function isSameRequest(request, reference) {
    return !!reference && angular.equals(request.model, reference.model);
  }

  function PaginatorPreloadStrategy(PipelineTransformer) {
    this.data = [];
    this.preloadedData = [];
    this.hasMoreRows = false;

    this.successPipeline = new PipelineTransformer(this);
    this.successPipeline.push(this.onRequestFulfilled);

    this.errorPipeline = new PipelineTransformer(this);
    this.errorPipeline.push(this.onRequestFailed);
  }

  PaginatorPreloadStrategy.prototype.reset = function () {
    this.request = this.lastRequest = this.lastPreloadRequest = undefined;
    this.hasMoreRows = false;
    this.lastPromise = undefined;
  };

  PaginatorPreloadStrategy.prototype.requestRows = function (
    appendResult,
    paginationModel,
    overrides
  ) {
    var normalModel = paginationModel;
    if (overrides) {
      normalModel = angular.extend({}, paginationModel, overrides);
    }

    var preloadModel;
    if (normalModel.limit) {
      preloadModel = angular.copy(paginationModel);
      preloadModel.skip = normalModel.skip + normalModel.limit;
    }

    this.createRequest({
      appendResult: appendResult,
      model: normalModel,
    });

    if (preloadModel) {
      this.createPreloadRequest({
        appendResult: appendResult,
        model: preloadModel,
      });
    }
  };

  PaginatorPreloadStrategy.prototype.createRequest = function (request) {
    if (isSameRequest(request, this.lastRequest) && !isRequestFulfilled(this.lastRequest)) {
      this.lastRequest.appendResult = request.appendResult;
      return;
    }

    if (!this._isRequestWasPreloaded(request)) {
      request.promise = this.getPromiseForRequest(request);

      this.lastRequest = request;
      this.lastPromise = this.lastRequest.promise;

      return;
    }

    this.lastRequest = this.lastPreloadRequest;

    if (isRequestFulfilled(this.lastPreloadRequest)) {
      this.lastRequest.appendResult = request.appendResult;
      this.successPipeline.transform(this.lastPreloadRequest, this.preloadedData);
    } else {
      this.lastRequest = this.lastPreloadRequest;
      this.lastPromise = this.lastRequest.promise;
    }

    this._clearPreloadRequest();
  };

  PaginatorPreloadStrategy.prototype.createPreloadRequest = function (request) {
    if (isSameRequest(request, this.lastPreloadRequest)) {
      return;
    }

    request.promise = this.getPromiseForRequest(request);

    // we dont want angular to spit out "Possibly unhandled rejection" error for preload request
    request.promise.catch(angular.noop);

    this.lastPreloadRequest = request;
  };

  PaginatorPreloadStrategy.prototype.isBusy = function () {
    return !!this.lastRequest && !isRequestFulfilled(this.lastRequest);
  };

  PaginatorPreloadStrategy.prototype.onRequestFulfilled = function (request, data) {
    if (request === this.lastRequest) {
      this._appendData(request, data || []);
      this.lastRequest = undefined;
      return data || [];
    }

    if (request === this.lastPreloadRequest) {
      this.preloadedData = data || [];
      this.hasMoreRows = this.preloadedData.length > 0;
    }
  };

  PaginatorPreloadStrategy.prototype.onRequestFailed = function (request, error) {
    if (request === this.lastPreloadRequest) {
      this.preloadedData = [];
      this.lastPreloadRequest = undefined;
    }

    return error;
  };

  PaginatorPreloadStrategy.prototype.getPromiseForRequest = function () {
    throw new Error('Paginator should override "getPromiseForRequest" method of strategy.');
  };

  PaginatorPreloadStrategy.prototype.retryLastRequest = function () {
    if (this.lastRequest) {
      this.lastRequest.promise = this.getPromiseForRequest(this.lastRequest);
      this.lastPromise = this.lastRequest.promise;

      return this.lastPromise;
    }

    throw new Error('Last request was success. You can not retry it!');
  };

  PaginatorPreloadStrategy.prototype._isRequestWasPreloaded = function (request) {
    return isSameRequest(request, this.lastPreloadRequest);
  };

  PaginatorPreloadStrategy.prototype._appendData = function (request, data) {
    if (request.appendResult) {
      this.data = this.data.concat(data);
    } else {
      this.data = data;
    }
  };

  PaginatorPreloadStrategy.prototype._clearPreloadRequest = function () {
    this.preloadedData = [];
    this.lastPreloadRequest = undefined;
  };

  function getStrategy() {
    return PaginatorPreloadStrategy;
  }

  angular.module('app.common').factory('PaginatorPreloadStrategy', getStrategy);
})();
