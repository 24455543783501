(function () {
  'use strict';

  function fromNowFilter() {
    return function (input) {
      if (typeof input === 'number') {
        return moment(input * 1000).fromNow();
      }

      return moment(input).fromNow();
    };
  }

  angular.module('app.common').filter('fromNow', fromNowFilter);
})();
