(function () {
  'use strict';

  function countryFlagDirective() {
    return {
      restrict: 'A',
      scope: {holder: '=countryFlag'},
      templateUrl: 'common/common/views/countryFlagDirective.html',

      link: function ($scope, $element, $attr) {
        $scope.$watch('holder', function (currentValue) {
          if (!currentValue) {
            return;
          }

          $scope.country = angular.copy($scope.holder.country);
          $scope.secondaryCountry = angular.copy($scope.holder.secondaryCountry);

          if ($scope.holder.country && $scope.holder.country.iso3166_3 === 'UCA') {
            $scope.country.iso3166_3 = 'USA';
            $scope.secondaryCountry = angular.extend({}, $scope.country, {iso3166_3: 'CAN'});
          }

          $scope.hasMultipleCountries = !!$scope.country && !!$scope.secondaryCountry;

          if ($scope.hasMultipleCountries) {
            $attr.$addClass('multicountry');
          }
        });
      },
    };
  }

  angular.module('app.common').directive('countryFlag', countryFlagDirective);
})();
