(function () {
  'use strict';

  var commonModule = angular.module('app.common');

  commonModule.value('ServiceHelper', {
    appendTransform: function (defaults, transform) {
      defaults = angular.isArray(defaults) ? defaults : [defaults];
      return defaults.concat(transform);
    },
  });
})();
