/**
 * Paginator Provider: Simplifies the handling of the pagination.
 *
 * Usages:
 *
 * <pre>
 *     funtion ExampleCtrl($scope, Paginator) {
 *         $scope.paginator = Paginator.getInstance({
 *             skip: skip,                      //optional - initial skip value
 *             limit: limit,                    //optional - provide it if you want to override the configured value
 *             sortBy: sortBy,                  //optional - initial sortBy
 *             direction: direction,            //optional - initial sort direction
 *             pagingFunction: pagingFunction,  //required - the paging function, has to return a promise
 *             pagingSuccess: pagingSuccess,    //optional - the callback when pagingFunction success
 *             pagingFailed: pagingFailed       //optional - the callback when pagingFunction failed
 *         });
 *     }
 * </pre>
 *
 * Available APIs:
 * - moreRows()
 * - sortBy(columnName)
 * - isSortedBy(columnName)
 * - getSortDirection()
 * - isBusy()
 * - reset()
 * - isNotLimited()
 * - retryLastRequest()
 */
(function () {
  'use strict';

  function PaginatorProvider() {
    var PaginatorClass,
      $config = {
        defaultRowsCount: 10,
        defaultStrategy: 'PaginatorPreloadStrategy',
      };

    this.setRowsCountPerPage = function (count) {
      $config.defaultRowsCount = count;
    };

    this.setDefaultStrategy = function (strategyFactoryName) {
      $config.strategy = strategyFactoryName;
    };

    this.$get = function (PaginatorBaseFactory) {
      return {
        addSortingPosition: function (data, key) {
          SportContract.utils.addSortingPosition(data, key);
        },
        getInstance: function (opts) {
          return PaginatorBaseFactory.getInstance($config, opts);
        },
      };
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.provider('Paginator', PaginatorProvider);
})();
