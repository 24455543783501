(function () {
  'use strict';

  angular.module('app.acl').directive('accessDenied', accessDeniedDirective);

  function accessDeniedDirective() {
    return {
      restrict: 'E',
      templateUrl: 'common/acl/views/accessDenied.html',
      replace: true,
      scope: {
        message: '=',
      },
    };
  }
})();
