(function () {
  'use strict';

  function PipelineTransformer(context) {
    Array.call(this);

    this.context = context;
  }

  PipelineTransformer.prototype = Object.create(Array.prototype);
  PipelineTransformer.prototype.constructor = PipelineTransformer;

  PipelineTransformer.prototype.transform = function () {
    var context = this.context,
      value = _.last(arguments),
      boundValues = _.initial(arguments);

    this.forEach(function (transformer) {
      if (transformer.fn) {
        value = transformer.fn.apply(transformer.context, boundValues.concat([value]));
      } else {
        value = transformer.apply(context, boundValues.concat([value]));
      }
    });

    return value;
  };

  angular.module('app.common').constant('PipelineTransformer', PipelineTransformer);
})();
