(function () {
  'use strict';

  angular.module('app.quicksearch').directive('quickSearch', quickSearch);

  function quickSearch(
    $rootScope,
    Searcher,
    PlayerSearcher,
    LeagueSearcher,
    StaffSearcher,
    AgentsSearcher,
    TeamSearcher,
    CountrySearcher,
    OrganizationSearcher,
  ) {
    const SEARCH_PROVIDERS = {
      players: PlayerSearcher,
      staffs: StaffSearcher,
      agents: AgentsSearcher,
      teams: TeamSearcher,
      leagues: LeagueSearcher,
      countries: CountrySearcher,
      organizations: OrganizationSearcher,
    };

    return {
      restrict: 'E',
      scope: {
        search: '=',
        providers: '@',
        onlyActive: '@',
      },
      templateUrl: function (elem, attr) {
        return attr.templateUrl
          ? attr.templateUrl
          : 'common/search/views/quickSearchDirective.html';
      },
      link: function (scope) {
        const quickSearch = new Searcher({
          onSuccessSearch: (name, searchResult) => {
            scope[name] = searchResult;
          },
        });

        const providers = scope.providers
          ? scope.providers.split(',')
          : Object.keys(SEARCH_PROVIDERS);

        providers.forEach(function (providerName) {
          if (providerName && SEARCH_PROVIDERS[providerName]) {
            quickSearch.registerSearchProvider(providerName, SEARCH_PROVIDERS[providerName]);
          }
        });

        scope.searcher = quickSearch;

        scope.$watch(
          'search',
          function (newval) {
            if (newval && newval.searchterm && newval.searchterm.length > 0) {
              quickSearch.doSearch(
                newval.searchterm,
                scope.onlyActive != 'false',
                newval.teamClass,
              );
            }
          },
          true,
        );

        scope.closeQuicksearch = function () {
          $rootScope.search.searchterm = '';
        };

        scope.getModuleForOrganizationResult = function (result) {
          return result.type === 'AGENCY'
            ? 'agency'
            : result.type === 'COMPANY'
            ? 'organizations.company'
            : result.type === 'UNION'
            ? 'organizations.union'
            : 'country.organization';
        };

        scope.$filterOrg = function (types) {
          return function (org) {
            return types.some(function (type) {
              return org.type === type;
            });
          };
        };
      },
    };
  }
})();
