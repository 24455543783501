(function () {
  function upperFirstFilter() {
    return function (input) {
      return _.upperFirst(input).replace('_', ' ');
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('upperFirst', upperFirstFilter);
})();
