(function () {
  'use strict';

  angular.module('app.common').directive('scHostname', scHostnameDirective);

  function scHostnameDirective() {
    return {
      restrict: 'A',

      link: function ($scope, $element, $attr) {
        $attr.$observe('href', function () {
          var hostname = $element[0].hostname.replace(/^www\./, '');

          $attr.$set('title', hostname);
          $element.text(hostname);
        });
      },
    };
  }
})();
