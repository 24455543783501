(function () {
  'use strict';

  angular.module('app.quicksearch').service('LeagueSearcher', LeagueSearcherConstructor);

  function LeagueSearcherConstructor(SearchService) {
    this.filters = [];

    this.doSearch = function (term) {
      if (term) {
        return SearchService.searchForLeagues(term);
      }
    };

    this.extractResult = function (result) {
      if (result && result.leagues instanceof Array) {
        return _.chain(result.leagues)
          .map(function (league) {
            return league._source;
          })
          .value();
      }

      return [];
    };
  }
})();
