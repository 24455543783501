(function () {
  'use strict';

  function abbreviationFilter() {
    return function (input) {
      if (!input) {
        return '';
      }

      input = input.split('_');
      input.forEach(function (chunk, index) {
        input[index] = chunk.substr(0, 1).toLocaleUpperCase();
      });

      return input.join('');
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('abbreviation', abbreviationFilter);
})();
