(function () {
  'use strict';

  var commonModule = angular.module('app.common');
  commonModule.factory('HttpErrorInterceptor', HttpErrorInterceptor);

  function HttpErrorInterceptor($rootScope, $q) {
    return {
      responseError: function (rejection) {
        $rootScope.$broadcast('httpError:' + rejection.status, {error: rejection.data});
        return $q.reject(rejection);
      },
    };
  }
})();
