(function () {
  'use strict';

  function ageFilter() {
    return function (input) {
      var format = typeof input === 'number' ? 'YYYY' : 'YYYY-MM-DD';
      input = moment(input, format);

      if (!input.isValid()) {
        return null;
      }

      return moment().diff(input, 'years');
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('age', ageFilter);
})();
