(function () {
  'use strict';

  angular.module('app.quicksearch').directive('searchResult', searchResultDirective);

  function searchResultDirective() {
    var TEMPLATE_MAPPING = {
      players: 'common/search/views/playerResults.html',
      staffs: 'common/search/views/staffResults.html',
      teams: 'common/search/views/teamResults.html',
      leagues: 'common/search/views/leagueResults.html',
      organizations: 'common/search/views/organizationResults.html',
    };

    return {
      restrict: 'E',
      templateUrl: function (element, attrs) {
        var providerName = attrs.providerName,
          templateUrl = attrs.templateUrl;

        if (templateUrl) {
          return templateUrl;
        }

        if (providerName) {
          return TEMPLATE_MAPPING[providerName] || '';
        }

        return '';
      },
    };
  }
})();
