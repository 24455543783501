(function () {
  'use strict';

  function EventsProvider() {
    var declaredEvents = {};

    this.declareEvent = function (events) {
      _.forEach(events, function (eventName) {
        declaredEvents[eventName] = true;
      });
    };

    this.$get = function EventsFactory($rootScope) {
      function publish(eventName) {
        if (declaredEvents[eventName] === undefined) {
          throw new Error('Nobody declared to publish event "' + eventName + '"');
        }

        arguments[0] = 'event~' + eventName;
        $rootScope.$emit.apply($rootScope, arguments);
      }

      function subscribe(eventName, handler) {
        if (declaredEvents[eventName] === undefined) {
          throw new Error('Nobody declared to publish event "' + eventName + '"');
        }

        return $rootScope.$on('event~' + eventName, function (event) {
          handler.apply(null, _.tail(arguments));
        });
      }

      function unsubscribe(subscription) {
        subscription();
      }

      return {
        publish: publish,
        subscribe: subscribe,
        unsubscribe: unsubscribe,
      };
    };
  }

  angular.module('app.common').provider('Events', EventsProvider);
})();
