(function () {
  'use strict';

  angular.module('app.quicksearch').service('CountrySearcher', CountrySearcherConstructor);

  function CountrySearcherConstructor(SearchService) {
    this.filters = [];

    this.doSearch = function (term) {
      if (term) {
        return SearchService.searchForCountries(term);
      }
    };

    this.extractResult = function (result) {
      if (result && result.countries instanceof Array) {
        return _.chain(result.countries)
          .map(function (item) {
            return item._source;
          })
          .value();
      }

      return [];
    };
  }
})();
