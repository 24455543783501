(function () {
  function commaSeparatedFilter() {
    return function (collection, byKey) {
      return (collection || [])
        .map(function (item) {
          return byKey ? item[byKey] : item;
        })
        .join(', ');
    };
  }

  angular.module('app.common').filter('commaSeparated', commaSeparatedFilter);
})();
