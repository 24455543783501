(function () {
  function capitalizeFilter() {
    const abbrs = ['pp', 'pk'];
    return function (input) {
      if (!input) {
        return '';
      }

      input = input.split('_');
      input.forEach(function (chunk, index) {
        if (abbrs.indexOf(chunk) > -1) {
          input[index] = chunk.toUpperCase();
        } else {
          var head = chunk.substr(0, 1).toLocaleUpperCase();
          var tail = chunk.substr(1).toLocaleLowerCase();
          input[index] = head + tail;
        }
      });

      return input.join(' ');
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('capitalize', capitalizeFilter);
})();
