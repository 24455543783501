(function () {
  'use strict';

  function searchFilter($filter) {
    var buildIndexes = function (items, fields) {
      var indexes = [];

      fields = fields.split(',');

      angular.forEach(items, function (item) {
        var itemIndexString = [];

        angular.forEach(fields, function (field) {
          var fieldValue = SportContract.utils.getFieldValue(item, field);
          if (typeof fieldValue === 'string') {
            fieldValue = fieldValue.replace(/[\000-\031]+/g, '');
            itemIndexString.push(fieldValue);
          }
        });
        indexes.push(itemIndexString.join(' ').toLowerCase());
      });

      return indexes;
    };

    return function (items, query, fields) {
      if (!items || !items.length) {
        return [];
      }

      if (!query) {
        return items;
      }

      if (!fields) {
        return [];
      }

      var indexes = buildIndexes(items, fields);

      var terms = query
        .replace(/[\000-\031]+/g, '')
        .toLowerCase()
        .split(' ');
      var results = [];
      for (var i = 0; i < indexes.length; i++) {
        var indexStr = indexes[i];

        if (indexStr) {
          var termMatches = 0;

          angular.forEach(terms, function (term) {
            if (indexStr.indexOf(term) > -1) {
              termMatches++;
            }
          });

          if (termMatches === terms.length) {
            results.push(items[i]);
            indexes[i] = '';
          }
        }
      }

      return results;
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('fullTextSearch', searchFilter);
})();
