(function () {
  'use strict';

  angular.module('app.common').directive('scModelExplicitNull', scModelExplicitNullDirective);

  function scModelExplicitNullDirective() {
    return {
      restrict: 'A',
      require: 'ngModel',

      link: function ($scope, $element, $attr, ngModel) {
        ngModel.$viewChangeListeners.push(function () {
          if (!ngModel.$modelValue && ngModel.$modelValue !== null) {
            ngModel.$viewValue = null;
            ngModel.$commitViewValue();
          }
        });
      },
    };
  }
})();
