(function () {
  'use strict';

  var commonModule = angular.module('app.common');
  commonModule.factory('FreshTokenInterceptor', FreshTokenInterceptor);

  function FreshTokenInterceptor($rootScope) {
    return {
      response: function (response) {
        var freshToken = response.headers('X-FRESH-TOKEN');
        if (freshToken) {
          $rootScope.$broadcast('freshTokenReceived', {token: freshToken});
        }
        return response;
      },
    };
  }
})();
