'use strict';

class InlineEditableController {
  private exposedAs: any;
  private _isEditing: boolean;

  constructor(
    private $element,
    private $scope,
  ) {
    this._isEditing = false;
  }

  public $onInit() {
    if (this.exposedAs && this.exposedAs.namespace) {
      const ns = this.exposedAs.namespace;
      this.$scope.$parent[ns] = {};
      this.exposedAs.apis.forEach((api) => {
        if (typeof this[api] === 'function') {
          this.$scope.$parent[ns][api] = this[api].bind(this);
        } else {
          this.$scope.$parent[ns][api] = this[api];
        }
      });
    }
  }

  public isEditing() {
    return this._isEditing;
  }

  public toggle() {
    this._isEditing = !this._isEditing;
  }
}

angular.module('app.common').component('inlineEditable', {
  template: '<ng-transclude />',
  controller: InlineEditableController,
  controllerAs: '$inlineEditableCtrl',
  transclude: true,
  bindings: {
    exposedAs: '<',
  },
});
