(function () {
  'use strict';

  /**
   * Helper function to build paginationed url easily.
   */
  function PaginationBuilderFactory() {
    var paginationModel = {};

    var addSkip = function (skipValue) {
      if (angular.isUndefined(skipValue) || isNaN(skipValue) || skipValue < 0) {
        paginationModel.skip = 0;
      } else {
        paginationModel.skip = skipValue;
      }

      return this;
    };

    var addLimit = function (limitValue) {
      if (angular.isDefined(limitValue) && !isNaN(limitValue) && limitValue > 0) {
        paginationModel.limit = limitValue;
      }

      return this;
    };

    var addSort = function (sortValue) {
      if (angular.isDefined(sortValue) && isNaN(sortValue)) {
        paginationModel.sort = sortValue;
      }

      return this;
    };

    var addDirection = function (directionValue) {
      if (
        angular.isUndefined(directionValue) ||
        isNaN(directionValue) ||
        (directionValue !== 1 && directionValue !== -1)
      ) {
        paginationModel.direction = 1;
      } else {
        paginationModel.direction = directionValue;
      }

      return this;
    };

    var build = function () {
      if (angular.isUndefined(paginationModel.skip)) {
        addSkip();
      }

      if (angular.isUndefined(paginationModel.direction)) {
        addDirection();
      }

      var resultModel = angular.copy(paginationModel);
      paginationModel = {};

      return resultModel;
    };

    return {
      addSkip: addSkip,
      addLimit: addLimit,
      addDirection: addDirection,
      addSort: addSort,
      build: build,
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.factory('PaginationBuilderFactory', PaginationBuilderFactory);
})();
