(function () {
  interface PendingAction {
    name: string;
    text: string;
  }

  class PendingActionsService {
    private actions: PendingAction[];

    constructor($rootScope, $window) {
      this.actions = [];

      window.onbeforeunload = (event) => {
        $rootScope.$broadcast('beforeunload');

        if (this.actions.length) {
          event.preventDefault();

          // note that for recent updates, Chome + Firefox has drop the support for custom messaging
          // shown in the dialog. The default text is shown instead.
          event.returnValue = this.formatMessage();
          return event.returnValue;
        }
      };
    }

    start(name: string, text: string) {
      this.actions.push({name, text});
    }

    finish(name: string) {
      this.actions = this.actions.filter((item) => item.name !== name);
    }

    formatMessage() {
      const rows = this.actions.map((item) => ` • ${item.text}`).join('\n');

      return `There are some jobs are still running:\n${rows}`;
    }
  }

  angular.module('app.common').service('PendingActionsService', PendingActionsService);
})();
