(function () {
  'use strict';

  angular.module('app.common').service('SeasonService', SeasonService);

  var SEASON_START_DATE = moment('07/01', 'MM-DD');

  // used by datahub when we want to switch to new season earlier than the normal start_date
  var SEASON_EARLY_START_DATE = moment('05/11', 'MM-DD');

  function SeasonService() {
    var seasonData = {
      start: '',
      end: '',
    };

    this.getSeasonData = function () {
      return seasonData;
    };

    this.getSeason = function () {
      return seasonData.season;
    };

    // gameSeason is the season that have 2 years
    this.getGameSeason = function (season) {
      season = season ? season : this.getSeason();
      return [`${season}`.substring(2), `${season + 1}`.substring(2)].join('');
    };

    this.getSeasonFromDate = function (dateStr) {
      var date = moment(dateStr, 'YYYY-MM-DD');
      var year = date.year();
      var currentYearStartDate = moment(SEASON_START_DATE).year(year);

      if (date.isAfter(currentYearStartDate)) {
        return year;
      }

      return year - 1;
    };

    this.setSeason = function (year) {
      year = year || this.getCurrentSeason();

      seasonData.start = moment(SEASON_START_DATE).year(year).format('YYYY-MM-DD');
      seasonData.end = moment(SEASON_START_DATE)
        .year(year + 1)
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      seasonData.season = year;
    };

    this.getSeasonDates = function (year) {
      return {
        start: moment(SEASON_START_DATE).year(year).toDate(),
        end: moment(SEASON_START_DATE)
          .year(year + 1)
          .subtract(1, 'days')
          .toDate(),
      };
    };

    this.getCurrentSeason = function (shouldUseEarlyStartDate) {
      // default var initialization does not work with ng-annotate: https://newbedev.com/ngannotate-warning-stringmap-expected-string-key
      shouldUseEarlyStartDate =
        shouldUseEarlyStartDate === undefined ? false : shouldUseEarlyStartDate;
      var currentYear = moment().year();
      var seasonStartDate = shouldUseEarlyStartDate
        ? moment(SEASON_EARLY_START_DATE).year(currentYear)
        : moment(SEASON_START_DATE).year(currentYear);

      return moment().isBefore(seasonStartDate) ? currentYear - 1 : currentYear;
    };

    this.getSeasonList = function (pastSeasonsOffset) {
      var currentSeason = this.getCurrentSeason();
      var list = [];

      pastSeasonsOffset =
        pastSeasonsOffset !== undefined && pastSeasonsOffset !== null ? pastSeasonsOffset : 9;

      for (var season = currentSeason + 1; season > currentSeason - pastSeasonsOffset; --season) {
        list.push({
          name: season,
          display: season + '/' + (season + 1),
        });
      }

      return list;
    };

    this.generateSeasonList = function (startYear, endYear) {
      if (!startYear || !endYear) {
        throw new Error('SeasonService.generateSeasonList: missing params');
      }

      var seasons = [];

      for (var i = endYear; i >= startYear; i--) {
        seasons.push({
          name: i,
          display: i + '/' + (i + 1),
        });
      }
      return seasons;
    };

    var self = this;
    initSeason();

    function initSeason() {
      const preferredSeason = localStorage.getItem('preferredSeason') || '';
      self.setSeason(parseInt(preferredSeason, 10) || '');
    }
  }
})();
