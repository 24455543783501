(function () {
  angular.module('app.acl').factory('ACLService', ACLService);

  function ACLService(UserService, $q) {
    return {
      hasPermissions: hasPermissions,
      hasPermissionsPromise: hasPermissionsPromise,
      hasAccountPermissions: hasAccountPermissions,
      hasAccountPermissionsPromise: hasAccountPermissionsPromise,
      isOrganizationOfType: isOrganizationOfType,
      isOrganizationOfTypePromise: isOrganizationOfTypePromise,
    };

    /**
     * permissions can be an array of element of following format:
     * context(:resource):permission
     */
    function hasPermissions(user, permissions, needAllToPass) {
      if (!user) {
        throw new Error('"user" not defined for permission check');
      }

      if (!user.permissions) {
        return false;
      }

      if (!angular.isArray(permissions)) {
        permissions = [permissions];
      }

      needAllToPass = typeof needAllToPass === 'undefined' ? true : needAllToPass;

      return _[needAllToPass ? 'every' : 'some'](
        _.map(permissions, function (permissionStr) {
          var parts = permissionStr.split(':');

          switch (parts.length) {
            case 2:
              return ensureUserHasPermissions(user, parts[0], 'default', parts[1]);

            case 3:
              return ensureUserHasPermissions(user, parts[0], parts[1], parts[2]);

            default:
              throw new Error('invalid permission string');
          }
        })
      );
    }

    function ensureUserHasPermissions(user, context, resource, permission) {
      if (context === '*') {
        var contexts = Object.keys(user.permissions);

        return contexts.some(function (ctx) {
          return ensureUserHasPermissions(user, ctx, resource, permission);
        });
      }

      if (!user.permissions[context]) {
        return false;
      }

      if (resource === '*') {
        var resources = Object.keys(user.permissions[context]);

        return resources.some(function (res) {
          return ensureUserHasPermissions(user, context, res, permission);
        });
      }

      if (resource !== 'default' && !user.permissions[context][resource]) {
        resource = 'default';
      }

      if (permission === '*') {
        return (
          user.permissions[context][resource] && user.permissions[context][resource].length > 0
        );
      }

      return _.includes(user.permissions[context][resource], permission);
    }

    /**
     * Return permission check as a promise (useful for ui-router's resolve)
     * @param  string|array  permissions
     * @return promise
     */
    function hasPermissionsPromise(permissions, needAllToPass) {
      return $q(function (resolve, reject) {
        UserService.getPromise().then(function (user) {
          if (permissions && !hasPermissions(user, permissions, needAllToPass)) {
            return reject('ACCESS_DENIED');
          }
          resolve(true);
        });
      });
    }

    function hasAccountPermissions(permissionNames, needAllToPass) {
      var user = UserService.getUser();
      var account = UserService.getActiveAccount();
      var organizationId = account.type !== 'user' ? account._id : '';
      var permissionContext = account.type + '-organization';

      if (!user || !account) {
        throw new Error('"user" or "account" not defined for permission check');
      }

      if (!user.permissions) {
        return false;
      }

      if (!angular.isArray(permissionNames)) {
        permissionNames = [permissionNames];
      }

      needAllToPass = typeof needAllToPass === 'undefined' ? true : needAllToPass;

      var permissionList = _.get(user.permissions, permissionContext + '.' + organizationId, []);

      return permissionNames[needAllToPass ? 'every' : 'some'](function (permissionName) {
        return _.includes(permissionList, permissionName);
      });
    }

    function hasAccountPermissionsPromise(permissionNames, needAllToPass) {
      return $q(function (resolve, reject) {
        UserService.getPromise().then(function () {
          if (!hasAccountPermissions(permissionNames, needAllToPass)) {
            return reject('ACCESS_DENIED');
          }
          resolve(true);
        });
      });
    }

    function isOrganizationOfType(orgType) {
      var user = UserService.getUser();
      return user.account && user.account.type === orgType.replace(/-organization$/, '');
    }

    function isOrganizationOfTypePromise(orgType) {
      return $q(function (resolve, reject) {
        UserService.getPromise().then(function () {
          if (isOrganizationOfType(orgType)) {
            resolve(true);
          } else {
            reject('ACCESS_DENIED');
          }
        });
      });
    }
  }
})();
