(function () {
  'use strict';

  var PLACEHOLDER_TEXT = '-';

  function scTablePlaceholder() {
    return function (input, placeholder) {
      return typeof input === 'undefined' || input === null || input === ''
        ? placeholder || PLACEHOLDER_TEXT
        : input;
    };
  }

  angular.module('app.common').filter('scTablePlaceholder', scTablePlaceholder);
})();
