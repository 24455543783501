(function () {
  'use strict';

  function metricToImperialFilter() {
    return function (rawInput, type, isImperial) {
      if (!isImperial || !rawInput) {
        return rawInput;
      }

      var input = parseFloat(rawInput);

      if (isNaN(input)) {
        return rawInput;
      }

      var result = 0;

      if (type) {
        switch (type) {
          case 'kg':
            result = Math.floor(input * 2.2046);
            break;
          case 'cm':
            var subResult = input * 0.032808;

            var total = Math.floor(subResult);
            var remaining = Math.round((subResult - total) * 12);

            result = total + "'" + remaining + '"';
            break;
          default:
            throw new Error("Got an unknown unit '" + type + "' for imperialFilter");
        }
      }

      return result;
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('metricToImperial', metricToImperialFilter);
})();
