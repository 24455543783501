(function () {
  function SecondsToTimeFilter() {
    return function (input, type, floatingDigitsCount) {
      if (input === '') {
        return '';
      }
      const precision = Math.pow(10, floatingDigitsCount || 0);

      const seconds = type === 'countdown' ? Math.ceil(input) : Math.floor(input);

      if (isNaN(seconds)) {
        return '';
      }

      var secondsPositive = Math.floor(Math.abs(seconds));

      var minutes = Math.floor(secondsPositive / 60);

      secondsPositive -= minutes * 60;

      var time = '';

      if (minutes !== 0 || time !== '') {
        minutes = minutes < 10 ? '0' + minutes : String(minutes);
        time += minutes + ':';
      }
      if (time === '') {
        time = '00:' + (secondsPositive < 10 ? '0' + secondsPositive : String(secondsPositive));
      } else {
        time += secondsPositive < 10 ? '0' + secondsPositive : String(secondsPositive);
      }

      if (type !== 'countdown' && floatingDigitsCount > 0) {
        time += '.' + Math.floor((input - seconds) * precision);
      }

      if (seconds < 0) {
        return '-' + time;
      }

      return time;
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.filter('secondsToTime', SecondsToTimeFilter);
})();
