(function () {
  'use strict';

  function seasonDropdownDirective($rootScope, UserService, $state, SeasonService) {
    return {
      restrict: 'E',
      scope: {
        onChange: '&',
      },
      templateUrl: 'common/common/views/seasonDropdown.html',
      link: function link($scope) {
        $scope.selectedSeason = {
          val: SeasonService.getSeason(),
        };

        $scope.seasons = SeasonService.getSeasonList();

        $scope.change = function () {
          if ($scope.onChange && $scope.onChange()) {
            $scope.onChange()($scope.selectedSeason.val);
          } else {
            SeasonService.setSeason($scope.selectedSeason.val);
            UserService.setSeason($scope.selectedSeason.val);
            $state.go($state.$current, null, {reload: true});
          }
        };
      },
    };
  }

  var commonModule = angular.module('app.common');
  commonModule.directive('seasonDropdown', seasonDropdownDirective);
})();
