/**
 * @ngdoc directive
 * @name com.2fdevs.videogular.plugins.controls.directive:vgScrubBar
 * @restrict E
 * @description
 * Directive to control the time and display other information layers about the progress of the media.
 * This directive acts as a container and you can add more layers to display current time, cuepoints, buffer or whatever you need.
 *
 * <pre>
 * <videogular vg-theme='config.theme.url'>
 *    <vg-media vg-src='sources'></vg-media>
 *
 *    <vg-controls vg-autohide='config.autohide' vg-autohide-time='config.autohideTime'>
 *        <vg-scrub-bar></vg-scrub-bar>
 *    </vg-controls>
 * </videogular>
 * </pre>
 *
 */

/**
 * Custom modification for Sportcontract to add the ability to show miniScrubBar when hover
 */

class ScMiniScrubBarController {
  private API;
  private zoomDuration;
  private isSeeking: boolean;
  private isPlaying: boolean;
  private isPlayingWhenSeeking: boolean;

  private $slider;
  private $handle;
  private sliderWitdth;

  private currentTime;
  private anchorTime;

  constructor(
    private $scope,
    private $timeout,
    private $element,
    private $document,
    private VG_STATES,
  ) {
    this.isSeeking = false;
    this.isPlaying = false;
    this.isPlayingWhenSeeking = false;
    this.zoomDuration = 60;
  }

  $postLink() {
    this.$slider = this.$element.find('div[role="slider"]');
    this.$handle = this.$element.find('a.handle');
    this.sliderWitdth = this.$slider[0].getBoundingClientRect().width;

    this.currentTime = 0;
    this.anchorTime = 0;

    this.$scope.$watch(
      () => {
        return this.API.currentTime;
      },
      (newVal) => {
        this.onUpdateTime(newVal);
      },
    );

    this.$scope.$watch(
      () => {
        return this.API.isFullScreen;
      },
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.$timeout(() => {
            this.sliderWitdth = this.$slider[0].getBoundingClientRect().width;
          }, 200);
        }
      },
    );

    this.$scope.$watch(
      () => {
        return this.API.currentState;
      },
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.setState(newVal);
        }
      },
    );

    this.$element.bind('mousedown.scminiscrubbar', this.onMiniScrubBarMouseDown.bind(this));
  }

  setState(newState) {
    if (!this.isSeeking) {
      switch (newState) {
        case this.VG_STATES.PLAY:
          this.isPlaying = true;
          this.$handle[0].style.left = this.$slider[0].scrollWidth / 2 + 'px';
          break;

        case this.VG_STATES.PAUSE:
          this.isPlaying = false;
          break;

        case this.VG_STATES.STOP:
          this.isPlaying = false;
          break;
      }
    }
  }

  onUpdateTime(newCurrentTime) {
    this.currentTime = newCurrentTime;
  }

  onMiniScrubBarMouseMove(event) {
    if (this.isSeeking) {
      const eventOffsetX = this.getEventOffsetX(event);
      const zoomSeconds = Math.max(0, (eventOffsetX * this.zoomDuration) / this.sliderWitdth);
      const seekTime = Math.min(
        this.anchorTime + zoomSeconds * 1000,
        this.API.mediaElement[0].duration * 1000,
      );

      this.API.seekTime(Math.max(seekTime / 1000, 0));

      if (seekTime > 0 && eventOffsetX > 0) {
        this.$handle[0].style.left = (eventOffsetX / this.sliderWitdth) * 100 + '%';
      }

      this.$scope.$apply();
    }
  }

  onMiniScrubBarMouseDown(event) {
    if (!this.API.isReady || this.API.isBuferring || !this.API.mediaElement[0].duration) {
      return;
    }

    this.isSeeking = true;

    this.$document.bind('mousemove.scminiscrubbar', this.onMiniScrubBarMouseMove.bind(this));
    this.$document.bind('mouseup.scminiscrubbar', this.onMiniScrubBarMouseUp.bind(this));

    if (this.isPlaying) {
      this.isPlayingWhenSeeking = true;
    }

    this.API.pause();

    this.anchorTime = this.currentTime - (this.zoomDuration / 2) * 1000;

    const eventOffsetX = this.getEventOffsetX(event);
    const zoomSeconds = Math.max(0, (eventOffsetX * this.zoomDuration) / this.sliderWitdth);
    const seekTime = Math.min(
      this.anchorTime + zoomSeconds * 1000,
      this.API.mediaElement[0].duration * 1000,
    );

    this.API.seekTime(Math.max(seekTime / 1000, 0));

    if (seekTime > 0) {
      this.$handle[0].style.left = (eventOffsetX / this.sliderWitdth) * 100 + '%';
    }

    this.$scope.$apply();
  }

  onMiniScrubBarMouseUp() {
    this.$document.off('mousemove.scminiscrubbar');
    this.$document.off('mouseup.scminiscrubbar');
    // if (isPlayingWhenSeeking) {
    //     isPlayingWhenSeeking = false;
    //     API.play();
    // }

    this.isSeeking = false;
  }

  getEventOffsetX(event) {
    const rect = this.$slider[0].getBoundingClientRect();
    let delta = event.clientX - rect.left;

    delta = Math.max(0, delta);
    delta = Math.min(delta, rect.width);

    return delta;
  }
}

angular.module('app.video').component('scMiniScrubBar', {
  template: `
        <span class="start">-30 sec</span>
        <span class="mid">{{$ctrl.currentTime | videoTime}}</span>
        <span class="end">+30 sec</span>
        <div role="slider">
            <a class="handle"></a>
            <div class="length"></div>
        </div>
    `,
  require: {
    API: '^videogular',
  },
  bindings: {},
  controller: ScMiniScrubBarController,
});
